import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  ButtonGroup,
  Button,
  Modal,
  Badge,
  Tooltip,
  OverlayTrigger,
  Spinner
} from 'react-bootstrap';
import { FaPlus, FaTrash, FaPen, FaEye } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';
import Api from '../../services/api';
import VehicleForm from './VehicleForm';
import { withSettingsStore } from '../common/settings-context';
import loglevel from '../../services/loglevel';
import { sortObjectArray } from '../../utils/utilityFunctions';
import notFound from '../../assets/no-data.png';
import { humanize, renderTooltip } from '../common/helpers';
import './Database.scss';

const Vehicles = withSettingsStore(({
  organizationSelect, getUnitByCode, getUnitById, isInRole
}) => {
  const { t } = useTranslation();

  const [vehicles, setVehicles] = useState([]);
  const [newVehicle, setNewVehicle] = useState({ id: -1 });
  const [showVehicleDialog, setShowVehicleDialog] = useState(false);
  const [emissionUnits, setEmissionUnits] = useState([]);
  const [units, setUnits] = useState([]);
  const [error, setError] = useState({
    title: t('common.Error'),
    text: '',
  });
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [modalTitle, setModalTitle] = useState(t('database.New vehicle'));
  const [loading, setLoading] = useState(false);
  const [viewVehicleId, setViewVehicleId] = useState(0);
  const [loadingElements, setLoadingElements] = useState(false);
  const [vehicleElements, setVehicleElements] = useState([]);
  const [vehicleTemplates, setVehicleTemplates] = useState([]);

  const emptyVehicle = () => ({
    id: 0,
    name: '',
    description: '',
    classification: '',
    emissionRoad: 0,
    emissionRoadFull: 0,
    emissionStreet: 0,
    emissionStreetFull: 0,
    load: 0, // not used
    totalMass: 0,
    vehicleMass: 0,
    deadWeight: 0,
    organizationId: 0,
    source: '',
  });
  const [showDeleteVehicleDialog, setShowDeleteVehicleDialog] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState(emptyVehicle());

  const displayError = useCallback((text, title) => {
    setError({ title: title === undefined ? t('common.Error') : title, text });
    setShowError(true);
  }, [t]);

  const retrieveVehicles = useCallback(async () => {
    setLoading(true);
    setVehicles(getVehicleByOrgId(organizationSelect));
    setLoading(false)
  }, [setVehicles]);

  const handleNewVehicle = () => {
    setNewVehicle(emptyVehicle());
    setShowVehicleDialog(true);
  };

  const handleSaveVehicle = async () => {
    console.log('Vehicle name:', newVehicle.name);
    const errorLog = []
    if (!newVehicle?.name?.trim()) {
      errorLog.push('name');
    }
    console.log(newVehicle);
    // If new vehicle is using any 'tkm' unit id's, then source field is mandatory
    // if (newVehicle.unitId == getUnitByCode('tkm', organizationSelect)?.[0]?.id && (newVehicle.source == '' || newVehicle.source == undefined || newVehicle.source == null)) {
    //   errorLog.push('source');
    // }
    if (!newVehicle?.source?.trim()) {
      errorLog.push('source');
    };
    
    setFormErrors(errorLog);
    console.log(errorLog);
    if (errorLog.length > 0) {
      return;
    }
    
    setLoading(true);
    loglevel.info('save', newVehicle);
    if (newVehicle.id <= 0) {
      newVehicle.organizationId = organizationSelect;
      await Api().transports().road().post(newVehicle);
      Api().settings.units()
        .then(result => {
          const filteredResults = result.filter(item => item.organizationId === organizationSelect);
          //console.log("Filtered results:", filteredResults);
        })
        .catch(error => {
          console.error("Error fetching units:", error);
        });
    } else {
      await Api().transports().road(newVehicle.id).update(newVehicle);
    }
    getVehicleByOrgId(organizationSelect);
    setLoading(false);
    setShowVehicleDialog(false);
  };

  const removeVehicle = async (vehicle) => {
    if (vehicle.isLinked) {
      handleRemoveVehicle(vehicle.id);
    } else {
      setVehicleToDelete(vehicle);
      console.log(vehicle);
      setShowDeleteVehicleDialog(true);
    }
  };

  const handleRemoveVehicle = async (id) => {
    loglevel.info(`remove ${id}`);
    try {
      if (id > 0) {
        await Api().transports().road(id).delete();
      }
      getVehicleByOrgId(organizationSelect);
    } catch (e) {
      displayError(t('database.failed-to-remove-transport'));
    }
  };

  const getVehicleByOrgId = useCallback(async (orgId) => {
    let res = await Api().transports().road().getVehiclesBasedOnOrgId(orgId);
    const sortedByEmission = sortObjectArray(res, 'emissionRoad', true)
    setVehicles(sortedByEmission);
    // console.log(sortedByEmission);
  }, [setVehicles])


  const retrieveUnitsByOrgId = useCallback(async (orgId) => {
    try {
      let units = await Api().settings.units();
      setUnits(units);
    } catch (error) {
      console.error('Error retrieving units by organization ID:', error);
    }
  }, []);

  const getUnitAbbreviation = useCallback((unitId) => {
    const unit = units.find((u) => u.id === unitId);
    if (unit === undefined) {
      return '';
    }
    return unit.abbreviation;
  }, [units]);

  function calculateAverage(value1, value2, value3, value4) {
    const sum = value1 + value2 + value3 + value4;
    const average = sum / 4;
    return average;
  }

  const tooltipEmissions = (
    <Tooltip id="tooltip-public">
      {t(
        "transportation.If the emission unit is per km the average of all four emission values is shown."
      )}
    </Tooltip>
  );

  useEffect(() => {
    retrieveVehicles();
    retrieveUnitsByOrgId()
  }, []);

  const handleEmissionUnit = async () => {
    try {
      //console.log("Selected Organization ID:", organizationSelect);
      const result = await Api().settings.units();
      const filteredResults = result.filter(item => item.organizationId === organizationSelect);
      console.log("Filtered results:", filteredResults);
      setEmissionUnits(filteredResults);  // Set emissionUnits state
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  useEffect(() => {
    if (organizationSelect !== null) {
      getVehicleByOrgId(organizationSelect);
      retrieveUnitsByOrgId(organizationSelect);
    }
  }, [organizationSelect]);

  // useEffect(() => {
  //   retrieveVehicles();
  // }, []);

  useEffect(() => {
    // Update the modal title when the language changes
    setModalTitle(t('database.New vehicle'));
  }, [t,]);

  const handleEditVehicle = (vehicle) => {
    const selectedEmissionUnit = getUnitAbbreviation(vehicle.unitId);

    const isEdit = vehicle.id > 0;

    const editedVehicle = {
      ...vehicle,
      selectedEmissionUnit: selectedEmissionUnit,
    };

    const newVehicle = emptyVehicle();

    const updatedVehicle = isEdit ? editedVehicle : newVehicle;

    setNewVehicle(updatedVehicle);
    setModalTitle(isEdit ? t('database.Edit Vehicle') : t('database.New vehicle'));
    setShowVehicleDialog(true);
  };

  const handleCloseModal = () => {
    // Reset the title when the modal is closed
    setModalTitle(t('database.New vehicle'));
    setShowVehicleDialog(false);
  };

  const handleViewRoadTransport = async (vehicle) => {
    if (viewVehicleId === vehicle.id) {
      setViewVehicleId(0);
    } else {
      setLoadingElements(true);
      setViewVehicleId(vehicle.id);
      setVehicleElements(await Api().transports().road(vehicle.id).elements(vehicle.id));
      setVehicleTemplates(await Api().transports().road(vehicle.id).templates(vehicle.id));
      setLoadingElements(false);
    }
  };

  return (
    <div>
      <Modal
        show={showDeleteVehicleDialog}
        onHide={() => {
          setShowDeleteVehicleDialog(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("database.Delete vehicle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("common.Are you sure you want to delete")} {vehicleToDelete.name}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeleteVehicleDialog(false)}>
            {" "}
            {t("common.Cancel")}
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => {
              handleRemoveVehicle(vehicleToDelete.id);
              setShowDeleteVehicleDialog(false);
            }}
          >
            <FaTrash /> {t("common.Delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showError}
        onHide={() => {
          setShowError(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{error.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error.text}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowError(false)}>{t("common.Close")}</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showVehicleDialog}
        onHide={() => {
          handleCloseModal();
          setFormErrors([]);
        }}
        dialogClassName="form-large"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VehicleForm vehicle={newVehicle} onChange={(v) => setNewVehicle(v)} emissionUnits={emissionUnits} handleEmissionUnit={handleEmissionUnit} getUnitAbbreviation={getUnitAbbreviation} selectedEmissionUnit={newVehicle.selectedEmissionUnit} organizationId={organizationSelect} errors={formErrors} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSaveVehicle()}>{t("common.Save")}</Button>
        </Modal.Footer>
      </Modal>
      <div className="button-toolbar">
        <div className="divider" />
        <ButtonGroup>
          <Button variant="outline-primary" onClick={() => handleNewVehicle()} disabled={!isInRole("Admin")}>
            <FaPlus /> {t("database.New vehicle")}
          </Button>
        </ButtonGroup>
      </div>
      {loading ? (
        <div className="position-fixed text-center" style={{ left: "50%", top: "50%" }}>
          <Spinner variant="primary" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div id="vehicle" className="table-container">
            <Table>
              <thead className="sticky1-header">
                <tr>
                  <th style={{ width: "2vw" }}></th>
                  <th style={{ width: "5vw" }}>{t("common.Name")}</th>
                  <th style={{ width: "5vw" }}>{t("database.Classification")}</th>
                  <th style={{ width: "5vw" }}>{t("database.Module")}</th>
                  <th style={{ width: "5vw" }}>
                    {t("common.Emission factor")}{" "}
                    <OverlayTrigger trigger={"hover"} placement="right" overlay={tooltipEmissions}>
                      <FiHelpCircle />
                    </OverlayTrigger>
                  </th>
                  <th style={{ width: "10vw" }}>{t("common.Unit")}</th>
                  <th style={{ width: "15vw" }}>{t("database.Source")}</th>
                  <th style={{ width: "10vw" }}>{t("database.Expiration Year")}</th>
                  <th style={{ width: "10vw" }}>{t("database.Created")}</th>
                  <th style={{ width: "10vw" }}>{t("database.Created By")}</th>
                  <th style={{ width: "5vw" }} />
                </tr>
              </thead>
              <tbody>
                {vehicles.length > 0 ? (
                  vehicles?.map((v) => (
                    <Fragment key={v.id}>
                      <tr key={v.id} style={{ background: v.isLinked ? "#d4edda" : "white" }} styles={{ background: v.isLinked ? "#d4edda" : "white" }}>
                        <td></td>
                        <td>{v.name}</td>
                        <td>{v?.classification}</td>
                        <td>{v.module}</td>
                        {v.unitId == getUnitByCode("tkm", organizationSelect)[0].id ? <td>{v.emissionAverage.toFixed(3)}</td> : <td>{calculateAverage(v.emissionRoad, v.emissionRoadFull, v.emissionStreet, v.emissionStreetFull).toFixed(3)}</td>}
                        <td>
                          <Badge key={v.unitId} className="m-1" variant="secondary">
                            {getUnitById(v.emissionUnitId).symbol} / {getUnitById(v.unitId).symbol}
                          </Badge>
                        </td>
                        <td>{v.source}</td>
                        <td>{v.expirationYear === 0 ? " " : v.expirationYear}</td>
                        <td>{humanize.date(v.createdAt, "date")}</td>
                        <td>{v.createdByName}</td>
                        <td className="text-right">
                          <ButtonGroup>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.Edit", 1))}>
                              <Button
                                variant="outline-primary"
                                onClick={() => {
                                  handleEditVehicle(v);
                                }}
                                disabled={!isInRole("Admin")}
                              >
                                <FaPen />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.View", 1))}>
                              <Button
                                disabled={!v.isLinked}
                                variant="outline-info"
                                onClick={() => {
                                  handleViewRoadTransport(v);
                                }}
                              >
                                <FaEye />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.Delete", 2))}>
                              <Button
                                variant="outline-danger"
                                onClick={() => {
                                  removeVehicle(v);
                                }}
                                disabled={!isInRole("Admin")}
                              >
                                <FaTrash />
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        </td>
                      </tr>
                      {viewVehicleId === v.id && (
                        <tr>
                          <td colSpan="11">
                            {loadingElements ? (
                              <div className="text-center">
                                <Spinner variant="primary" animation="border" role="status">
                                  <span className="sr-only">{t("Loading...")}</span>
                                </Spinner>
                              </div>
                            ) : (
                              <div>
                                {vehicleElements.length > 0 && (
                                <Table className="table-sm">
                                  <thead>
                                    <tr style={{ fontStyle: 'italic' }}>
                                      <th colSpan="7">{t("element.Linked elements")}</th>
                                    </tr>
                                    <tr className="view-header">
                                      <th>{t("common.Element name")}</th>
                                      <th>{t("project.Project")}</th>
                                      <th>{t("scenario.Scenario")}</th>
                                      <th>{t("common.Code")}</th>
                                      <th>{t("common.Unit")}</th>
                                      <th>{t("common.Description")}</th>
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody className='view-body'>
                                    {vehicleElements.map((e) => (
                                      <tr key={e.id}>
                                        <td>{e.name}</td>
                                        <td>{e.projectName}</td>
                                        <td>{e.scenarioName}</td>
                                        <td>{e.code}</td>
                                        <td>{getUnitById(e.unitId).symbol}</td>
                                        <td>{e.description}</td>
                                        <td />
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                )}
                                {vehicleTemplates.length > 0 && (
                                <Table className="table-sm">
                                  <thead>
                                    <tr style={{ fontStyle: 'italic' }}>
                                      <th colSpan="11">{t("element.Linked templates")}</th>
                                    </tr>
                                    <tr className="view-header">
                                      <th>{t("common.Template name")}</th>
                                      <th>{t("common.Code")}</th>
                                      <th>{t("common.Unit")}</th>
                                      <th>{t("common.Description")}</th>
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody className='view-body'>
                                    {vehicleTemplates.map((e) => (
                                      <tr key={e.id}>
                                        <td>{e.name}</td>
                                        <td>{e.code}</td>
                                        <td>{getUnitById(e.unitId).symbol}</td>
                                        <td>{e.description}</td>
                                        <td />
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <tr style={{ height: "60vh" }}>
                    <td colSpan="10" className="text-center">
                      <div className="mt-4 d-flex-col">
                        <div className="inner-div">
                          <img src={notFound} className="img-responsive" style={{ height: "70px" }} alt="Not found" />
                        </div>
                        <div className="inner-div">
                          <h1 style={{ fontSize: "20px" }}>{t("database.Data Not Available in the Database")}</h1>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
})

export default Vehicles;
