import React, {
  useState, useEffect, useCallback, Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table,
  Modal,
  ButtonGroup,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Popover,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap';
import {
  FaPlus, FaTrash, FaPen, FaInfoCircle, FaEye
} from 'react-icons/fa';
import Api from '../../services/api';
import IPhaseForm from './IPhaseForm';
import notFound from '../../assets/no-data.png';

import { withSettingsStore } from '../common/settings-context';

const InstallationPhases = withSettingsStore(({ isInRole, organizationSelect, displayError, getUnitById, ...props }) => {
  const { t } = useTranslation();
  const emptyPhase = () => ({
    id: 0,
    name: '',
    description: '',
    organization: '',
    organization_id: '',
    options: [],
  });

  const [phases, setPhases] = useState([]);
  const [showPhaseDialog, setShowPhaseDialog] = useState(false);
  const [showEditPhaseDialog, setShowEditPhaseDialog] = useState(false);
  const [newPhase, setNewPhase] = useState(emptyPhase());
  const [searchText, setSearchText] = useState('');
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeletePhaseDialog, setShowDeletePhaseDialog] = useState(false);
  const [phaseToDelete, setPhaseToDelete] = useState(emptyPhase());
  const [sqlError, setSQLError] = useState(false);
  const [phaseError, setPhaseError] = useState(false);
  const [optionError, setOptionError] = useState(false);
  const [viewPhaseId, setViewPhaseId] = useState(0);
  const [loadingElements, setLoadingElements] = useState(false);
  const [phaseElements, setPhaseElements] = useState([]);
  const [phaseTemplates, setPhaseTemplates] = useState([]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('installation.Click the New Phase button to create a new phase')}
          </Row>
          <Row>
            {t('installation.Add all the different options associated with the phase')}
          </Row>
          <Row>
            {t('installation.Add machines and their respective conversions')}
          </Row>
          <Row>
            {t('installation.If machines do not contain the desired conversions, they can be updated via Databases -> Machines')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );

  const retrievePhases = useCallback(async () => {
    clearTimeout(currentTimeout);
    setLoading(true);
    setCurrentTimeout(setTimeout(async () => {
      const m = await Api().installationPhases().get({});
      const sortedByOrganization = m.filter(phase => phase.organization_id === organizationSelect);
      setPhases(sortedByOrganization);

      setLoading(false);
    }, 1000));
  }, [searchText, organizationSelect]);

  const handleSavePhase = async () => {
    console.log(newPhase);
    let hasError = false;

    if (newPhase.name === "" || newPhase.options.length <= 0) {
      hasError = true;
      setPhaseError(true);
    }
    else if (newPhase.options.length > 0) {
      for (const option of newPhase.options) {
        if (option.machineConversionId == null || option.machineConversionId == undefined) {
          hasError = true;
          setOptionError(true);
          break;
        }
      }
    }
    if (!hasError) {
      try {
        setLoading(true);
        if (newPhase.id > 0) {
          let response = await Api().installationPhases(newPhase.id).update(newPhase);
          console.log(response);
          if (!response.ok) {
            console.log(response.text);
          }
        } else {
          newPhase.organization_id = organizationSelect;
          await Api().installationPhases().post(newPhase);
        }
        setPhaseError(false);
        setSQLError(false);
        setOptionError(false);
        setLoading(false);
        setShowPhaseDialog(false);
        retrievePhases();
      } catch (error) {
        console.log(error.response);
        if(error.response.status == 409) setSQLError(true);
        setLoading(false);
      }
    }
  };

  const removePhases = async (installationPhase) => {
    const linkedOptionExists = installationPhase.options.some(option => option.isLinked);

    if (linkedOptionExists) {
      handleRemovePhases(installationPhase.id);
    } else {
      setPhaseToDelete(installationPhase);
      setShowDeletePhaseDialog(true);
    }
  };

  const handleRemovePhases = async (id) => {
    try {
      await Api().installationPhases(id).delete();
    } catch (e) {
      displayError(t('database.failed-to-remove-installationPhase'));
    }
    retrievePhases();
  };

  const handleNewPhase = () => {
    setNewPhase(emptyPhase());
    setShowEditPhaseDialog(false)
    setShowPhaseDialog(true);
  };

  const handleEditPhase = (phase) => {
    setNewPhase(phase);
    setShowEditPhaseDialog(true)
    setShowPhaseDialog(true);
  };

  const handleViewPhase = async (phase) => {
    if (viewPhaseId === phase.id) {
      setViewPhaseId(0);
    } else {
      setLoadingElements(true);
      setViewPhaseId(phase.id);
      setPhaseElements(await Api().installationPhases(phase.id).elements());
      setPhaseTemplates(await Api().installationPhases(phase.id).templates());
      setLoadingElements(false);
    }
  };

  const filterBySearch = useCallback((m) => m.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0, [searchText]);

  useEffect(() => {
    if (organizationSelect !== null) {
      retrievePhases();
    }
    return () => {
      setPhaseError(false);
      setSQLError(false);
      setOptionError(false);
    }
  }, [retrievePhases, organizationSelect]);

  const customStyles = {
    row: (provided, state) => ({
      ...provided,
      backgroundColor: state.data?.value?.isLinked ? '#cef0d6' : 'transparent', // Change background color based on the isLinked property
    }),
  };

  return (
    <div>
      <Modal show={showDeletePhaseDialog} onHide={() => { setShowDeletePhaseDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('database.Delete Installation Phase')}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('common.Are you sure you want to delete')}
            {' '}
            {phaseToDelete.name}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeletePhaseDialog(false)}>
            {' '}
            {t('common.Cancel')}
          </Button>
          <Button variant="outline-danger" onClick={() => { handleRemovePhases(phaseToDelete.id); setShowDeletePhaseDialog(false) }}>
            <FaTrash />
            {' '}
            {t('common.Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPhaseDialog} onHide={() => { setPhaseError(false); setSQLError(false); setOptionError(false); setShowPhaseDialog(false); }} dialogClassName="form-large">
        <Modal.Header closeButton>
          <Modal.Title>{showEditPhaseDialog ? t('Edit') : t('database.New phase')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IPhaseForm installationPhase={newPhase} onChange={(m) => { setNewPhase(m) }} projectOrg={props.organizationSelect} organizationId={organizationSelect} phaseError={phaseError} sqlError={sqlError} optionError={optionError} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSavePhase()}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>

      <div className="button-toolbar">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('common.Search')}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl onChange={(e) => { setSearchText(e.target.value); }} />
        </InputGroup>
        <div className="divider" />
        <ButtonGroup>
          <Button variant="outline-primary" onClick={() => handleNewPhase()}>
            <FaPlus />
            {' '}
            {t('database.New phase')}
          </Button>
          <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
            <Button variant="outline-primary" disabled>
              <FaInfoCircle size="15" />
            </Button>
          </OverlayTrigger>

        </ButtonGroup>
      </div>
      {loading ? (
        <div className="position-fixed text-center" style={{ left: '50%', top: '50%' }}>
          <Spinner variant="primary" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div id="installation-phase" className="table-container">
            <Table className="table-sm">
              <thead className="sticky2-header">
                <tr>
                <th style={{ width: '2vw' }} />
                <th style={{ width: '25vw' }}>{t('common.Name')}</th>
                <th style={{ width: '30vw' }}>{t('common.Description')}</th>
                <th style={{ width: '25vw' }}>{t('import.Options')}</th>
                <th style={{ width: '10vw' }} />
                </tr>
              </thead>
              <tbody>
              {phases.length > 0 ? (phases.filter(filterBySearch).flatMap((m) => (
                    <>
                      {m.options && m.options.length > 0 ? (
                        m.options.map((option, index) => (
                          <>
                        <tr key={`${m.id}_${index}`} style={{ background: option.isLinked ? '#d4edda' : 'white', }}>
                          <td style={{ height: '45px', borderBottom: index === m.options.length - 1 ? '1px solid #808080' : '1px solid transparent' }} />
                          <td style={{ height: '45px', borderBottom: index === m.options.length - 1 ? '1px solid #808080' : '1px solid transparent' }}>{index === 0 ? m.name : ''}</td>
                          <td style={{ height: '45px', borderBottom: index === m.options.length - 1 ? '1px solid #808080' : '1px solid transparent' }}>{index === 0 ? m.description : ''}</td>
                          <td style={{ height: '45px', borderBottom: index === m.options.length - 1 ? '1px solid #808080' : '1px solid transparent' }}>{option.name}</td>
                          <td className='text-right' style={{ borderBottom: index === m.options.length - 1 ? '1px solid #808080' : '1px solid transparent' }}>
                                {index === 0 ? (
                                  <ButtonGroup>
                                    <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.Edit", 1))}>
                                      <Button variant="outline-info" onClick={() => { handleEditPhase(m); }}><FaPen /></Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("View", 1))}>
                                      <Button
                                        disabled={!m.options.some(option => option.isLinked)}
                                        variant="outline-info"
                                        onClick={() => {
                                          handleViewPhase(m);
                                        }}
                                      >
                                        <FaEye />
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger 
                                    placement="left" 
                                    delay={{ show: 250, hide: 400 }} 
                                    overlay={renderTooltip(t('database.Delete', 2))}
                                    >
                                  <Button variant="outline-danger" onClick={() => { removePhases(m) }}><FaTrash /></Button>
                                    </OverlayTrigger>
                                  </ButtonGroup>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                    <tr style={{ background: 'white' }}>
                      <td style={{ width: '50px', height: '45px', borderBottom: '1px solid #808080' }} />
                      <td style={{ width: '500px', height: '45px', borderBottom: '1px solid #808080' }}>{m.name}</td>
                      <td style={{ width: '500px', height: '45px', borderBottom: '1px solid #808080' }}>{m.description}</td>
                      <td style={{ height: '45px', borderBottom: '1px solid #808080' }}></td>
                      <td className='text-right' style={{ borderBottom: '1px solid #808080' }}>
                            <ButtonGroup>
                              <OverlayTrigger 
                              placement="left" 
                              delay={{ show: 250, hide: 400 }} 
                              overlay={renderTooltip(t('Edit', 1))}
                              >
                                <Button
                                  variant="outline-info"
                                  onClick={() => {
                                    handleEditPhase(m);
                                  }}
                                >
                                  <FaPen />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("View", 1))}>
                                <Button
                                  disabled={true}
                                  variant="outline-info"
                                  onClick={() => {
                                    handleViewPhase(m);
                                  }}
                                >
                                  <FaEye />
                                </Button>
                              </OverlayTrigger>
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip(t('Delete', 2))}
                          >
                            <Button variant="outline-danger" onClick={() => { removePhases(m); }}><FaTrash /></Button>
                              </OverlayTrigger>
                            </ButtonGroup>
                          </td>
                        </tr>
                      )}
                      {viewPhaseId === m.id && (
                        <tr>
                          <td colSpan="9">
                            {loadingElements ? (
                              <div className="text-center">
                                <Spinner variant="primary" animation="border" role="status">
                                  <span className="sr-only">{t("Loading...")}</span>
                                </Spinner>
                              </div>
                            ) : (
                              <div>
                                {phaseElements.length > 0 && (
                                <Table className="table-sm">
                                  <thead>
                                    <tr style={{ fontStyle: "italic" }}>
                                      <th colSpan="7">{t("element.Linked elements")}</th>
                                    </tr>
                                    <tr className="view-header">
                                      <th>{t("common.Element name")}</th>
                                      <th>{t("project.Project")}</th>
                                      <th>{t("scenario.Scenario")}</th>
                                      <th>{t("common.Code")}</th>
                                      <th>{t("common.Unit")}</th>
                                      <th>{t("common.Description")}</th>
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody className='view-body'>
                                    {phaseElements.map((e) => (
                                      <tr key={e.id}>
                                        <td>{e.name}</td>
                                        <td>{e.projectName}</td>
                                        <td>{e.scenarioName}</td>
                                        <td>{e.code}</td>
                                        <td>{getUnitById(e.unitId).symbol}</td>
                                        <td>{e.description}</td>
                                        <td />
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                )}
                                {phaseTemplates.length > 0 && (                        
                                <Table className="table-sm">
                                  <thead>
                                    <tr style={{ fontStyle: "italic" }}>
                                      <th colSpan="7">{t("element.Linked templates")}</th>
                                    </tr>
                                    <tr className="view-header">
                                      <th>{t("common.Template name")}</th>
                                      <th>{t("common.Code")}</th>
                                      <th>{t("common.Unit")}</th>
                                      <th>{t("common.Description")}</th>
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody className='view-body'>
                                    {phaseTemplates.map((e) => (
                                      <tr key={e.id}>
                                        <td>{e.name}</td>
                                        <td>{e.code}</td>
                                        <td>{getUnitById(e.unitId).symbol}</td>
                                        <td>{e.description}</td>
                                        <td />
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr style={{ height: "60vh" }}>
                    <td colSpan="10" className="text-center">
                      <div className="mt-4 d-flex-col">
                        <div className="inner-div">
                        <img src={notFound} className="img-responsive" style={{ height: '70px' }} alt="Not found" />
                        </div>
                        <div className="inner-div">
                        <h1 style={{ fontSize: "20px" }}>{t('database.Data Not Available in the Database')}</h1>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
});

const renderTooltip = (name, id) => (
  <Tooltip id={id}>
    {name}
  </Tooltip>
);

export default InstallationPhases;