import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { FaPlus, FaList, FaTh, FaCaretRight } from 'react-icons/fa';

import {
  Container,
  Card,
  CardDeck,
  ButtonToolbar,
  Button,
  ButtonGroup,
  Modal,
  Table,
  Row,
  Col,
  Badge,
  Spinner,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Api from '../../services/api';
import ProjectForm from './ProjectForm';
import Map from '../common/Map';
import './Home.scss';

import { humanize } from '../common/helpers';
import { withSettingsStore, SettingsContext } from '../common/settings-context';
import News from './news';

const ProjectCard = (props) => {
  const { t } = useTranslation();
  const { project: p } = props;
  const { user } = useContext(SettingsContext);  

  const getDataSource =()=>{
    switch(p.organizationId) {
      case 1: return t('organization.Finland Organization');
      case 2: return t('organization.Danish Organization');
      case 3: return t('organization.UK Organization');
      case 4: return t('organization.Germany Organization');
      case 6: return t('organization.Major Crossings');
      case 7: return t('organization.Sweden');
      case 8: return t('organization.Norway');
      default: return null;
    }
  }
  return (
    <LinkContainer to={`/project/${p.id}`}>
      <Card className="project-card">
        {p.isTemplate && (
          <Badge
            pill
            className="d-inline"
            style={{
              position: "absolute",
              zIndex: 1000,
              top: "0.5em",
              left: "0.5em",
            }}
            variant="primary"
          >
            {t("project.Example")}
          </Badge>
        )}
        {p.longitude !== 0 ? (
          <Map
            height="200"
            noControls
            key={`${p.locationLatitude}x${p.locationLongitude}`}
            position={{
              lat: p.locationLatitude,
              lng: p.locationLongitude,
            }}
          />
        ) : (
          <Card.Img variant="top" className="p-4" src="/logo256.png" alt="" />
        )}
        <Card.Body className="p-3">
          {user.username !== "Public Access User" && (
            <>
              {p.roleName === "ProjectAdmin" && (
                <Badge variant="primary" className="mb-2">
                  {t("project.ProjectAdmin")}
                </Badge>
              )}
              {p.roleName === "ProjectUser" && (
                <Badge variant="secondary" className="mb-2">
                  {t("project.ProjectUser")}
                </Badge>
              )}
              {p.roleName === "ProjectReader" && (
                <Badge variant="secondary" className="mb-2">
                  {t("project.ProjectReader")}
                </Badge>
              )}
              {<br></br>}
              {p.organizationId !== undefined && (
                <Badge variant="success" style={{ backgroundColor: "#ADD095" }}>
                  {getDataSource()}
                </Badge>
              )}
            </>
          )}
          <Button variant="link" className="project-card__title">
            {p.name}
          </Button>
          <p className="project-card__description">{p.description}</p>
        </Card.Body>
      </Card>
    </LinkContainer>
  );
};

const Home = withSettingsStore((props) => {
  const defaultProjectState = {
    name: '',
    description: '',
    id: -1,
    projectNumber: '',
    type: '',
    phase: '',
    status: 1,
    managerName: '',
    started: new Date().toISOString().split('T')[0],
    ended: '',
    tags: [],
    groups: [
      {
        name: '',
        description: '',
      },
    ],
    organizationId: -1,
  };
  const { t } = useTranslation();

  const { isInRole, refreshSettings, projectTemplates, currentRoles } = props;
  const [projects, setProjects] = useState([]);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [project, setProject] = useState(defaultProjectState);

  const [orderBy, setOrderBy] = useState('started');
  const [isAscending, setIsAscending] = useState(false);
  const [isInstallingTemplates, setIsInstallingTemplates] = useState(false);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [showTemplates, setShowTemplates] = useState(true);
  const [loading, setLoading] = useState(true);

  const getProjects = useCallback(async () => {

    const retrievedProjects = await Api().projects().get({});
    // Set projects
    setProjects([...retrievedProjects]);

    return retrievedProjects;
  }, [props]);


  useEffect(() => {
    setLoading(true);
    getProjects().then((ps) => {
      // Handle template projects

      const ownProjects = ps.filter(
        (p) => props.currentRoles.filter((r) => r.projectId === p.id).length > 0,
      );
      if (
        (ownProjects.length === 0 ||
          ownProjects.filter((o) => o.isTemplate).length === 0) &&
        projectTemplates.length > 0
      ) {
        // Trigger role update
        setIsInstallingTemplates(true);
        Api()
          .settings.installTemplates()
          .then(async () => {
            await refreshSettings();
            await getProjects();
            setIsInstallingTemplates(false);
          });
      }
      setLoading(false);
    }).catch(e => {
      console.log(e);
    }).finally(()=> setLoading(false));
  }, []);

  const filteredProjects = useMemo(() => {
    let projs = projects.map((p) => {
      const projectRole = props.currentRoles.filter(
        (r) => r.projectId === p.id,
      );
      let roleName = '';
      if (projectRole.length > 0) {
        roleName = projectRole[0].name;
      }
      return {
        ...p,
        roleName,
      };
    });

    if (!showAllProjects) {
      projs = projs.filter((p) => p.roleName !== '');
    }
    projs = projs.filter((p) => (showTemplates ? true : !p.isTemplate));
    projs = projs.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
    if (!isAscending) {
      projs.reverse();
    }
    return projs;
  }, [
    isAscending,
    orderBy,
    projects,
    showAllProjects,
    props.currentRoles,
    showTemplates,
  ]);

  async function handleCreateProject() {
    const error = [];
    setErrors([]);
    if (project.name === '') {
      error.push('name');
    }

    if (project.organizationId === -1) {
      error.push('projectOrganization');
    }

    if (project.type === '') {
      error.push('type')
    }
    
    // check for start date and end date
    // project.started > project.ended --> Error
    let startDate = project.started;
    let endDate = project.ended;
    if(Date.parse(startDate) > Date.parse(endDate)){
      error.push('startDate')
    }

    setErrors(error);

    if (error.length === 0) {
      const rightFormProject = {
        ...project,
        id: -1,
        type: parseInt(project.type, 10),
        status: parseInt(project.status, 10),
        organizationId: parseInt(project.organizationId, 10),
      };

      await Api()
        .projects()
        .post(rightFormProject)
        .then(async () => {
          await props.refreshSettings();
          setShowProjectDialog(false);
          getProjects();
        });
      setProject(defaultProjectState);
    }
  }

  const toggleOrderBy = (field) => {
    if (orderBy !== field) {
      setOrderBy(field);
    } else {
      setIsAscending((a) => !a);
    }
  };

  return (
    <Container fluid>
      <Modal
        show={showProjectDialog}
        onHide={() => {
          setShowProjectDialog(false);
          setErrors([]);
          setProject(defaultProjectState);
        }}
        dialogClassName="form-large"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('project.Create project')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectForm
            project={project}
            onChange={(p) => setProject(p)}
            errors={errors}
            fromEditForm={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCreateProject()}>
            {t('project.Create project')}
          </Button>
        </Modal.Footer>
      </Modal>
      <ButtonToolbar className="my-4">
        <ButtonGroup>
          {(props.user.username != 'Public Access User') && (
            <Button
              variant="outline-primary"
              onClick={() => setShowTable((show) => !show)}
            >
              {showTable ? <FaTh /> : <FaList />}
            </Button>
          )}
          {isInRole('Admin') && (
            <>
              <Button
                variant="outline-primary"
                onClick={() => setShowAllProjects((show) => !show)}
              >
                {showAllProjects
                  ? t('project.Show my projects')
                  : t('project.Show all projects')}
              </Button>
              {projects.some((template) => template.isTemplate === true) && (
                <Button
                  variant="outline-primary"
                  onClick={() => setShowTemplates((show) => !show)}
                >
                  {showTemplates
                    ? t('project.Hide templates')
                    : t('project.Show templates')}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>

        <ButtonGroup className="ml-auto">

          {!(currentRoles[0].name === 'ProjectReader' || props.user.username == 'Public Access User') && (
            <Button
              variant="outline-primary"
              onClick={() => setShowProjectDialog(true)}
            >
              <FaPlus /> {t('project.New project')}
            </Button>
          )}
        </ButtonGroup>
      </ButtonToolbar>
      {loading ? (
        <div className="position-fixed text-center" style={{ left: '50%', top: '50%' }}>
          <Spinner variant="primary" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) :
        <Row>
          <Col xs="9">
            {/* <h1>{t('project.Projects')}</h1> */}
            <h2 className="h4" style={{marginBottom:'24px'}}>              
            {props.user.username == 'Public Access User' ? t('project.Shared project') : t('project.Active projects')}
            </h2>
            {isInstallingTemplates && (
              <Card className="text-center">
                <Card.Body>
                  <Spinner animation="grow" variant="primary" />
                  <p>{t('project.Installing examples')}</p>
                </Card.Body>
              </Card>
            )}
            {!showTable && (
              <>
                <CardDeck className="my-2 mb-4">
                  {filteredProjects
                    .filter((p) => p.status !== 2)
                    .map((p) => (
                      <ProjectCard key={p.id} project={p} />
                    ))}
                </CardDeck>
                {projects.filter((p) => p.status === 2).length > 0 && (
                  <>
                    <h2 className="h4" style={{marginBottom:'24px'}}>{t('project.Archived projects')}</h2>
                    <CardDeck className="my-2">
                      {filteredProjects
                        .filter((p) => p.status === 2)
                        .map((p) => (
                          <ProjectCard key={p.id} project={p} />
                        ))}
                    </CardDeck>
                  </>
                )}
              </>
            )}
            {showTable && (
              <>
                <Table striped>
                  <thead className="sortable-table">
                    <tr>
                      <th onClick={() => toggleOrderBy('roleName')}>
                        {t('project.Role')}
                      </th>
                      <th onClick={() => toggleOrderBy('name')}>
                        {t('common.Name')}
                      </th>
                      <th onClick={() => toggleOrderBy('managerName')}>
                        {t('project.Project manager')}
                      </th>
                      <th onClick={() => toggleOrderBy('projectNumber')}>
                        {t('project.Project number')}
                      </th>
                      <th onClick={() => toggleOrderBy('type')}>
                        {t('project.Project type')}
                      </th>
                      <th onClick={() => toggleOrderBy('started')}>
                        {t('project.Project started')}
                      </th>
                      <th onClick={() => toggleOrderBy('status')}>
                        {t('project.Status')}
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProjects.map((p) => (
                      <tr key={p.id}>
                        <td>{t(p.roleName)}</td>
                        <td>{p.name}</td>
                        <td>{p.managerName}</td>
                        <td>{p.projectNumber}</td>
                        <td>{t(props.getProjectTypeById(p.type))}</td>
                        <td>{humanize.date(p.started, 'date')}</td>
                        <td>{t(props.getProjectStatusById(p.status))}</td>
                        <td>{t(props.getProjectPhaseById(p.phase))}</td>
                        <td>
                          <LinkContainer to={`/project/${p.id}`}>
                            <Button variant="info">
                              <FaCaretRight />
                            </Button>
                          </LinkContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Col>
          <Col xs="3" style={{marginTop:'50px'}}>
            <News />
          </Col>
          {/* <Col xs="12" md="4" xl="3">
          <Accordion>
            <Card>
              <Card.Header>{t('Notifications')}</Card.Header>
              <ListGroup>
                <Accordion.Toggle as={ListGroup.Item} variant="link" eventKey="0">
                  Click me!
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>Hello! I'm the body</Card.Body>
                </Accordion.Collapse>
              </ListGroup>
            </Card>
          </Accordion>
        </Col> */}
        </Row>
      }

    </Container>
  );
});

export default Home;
